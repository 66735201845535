<template>
    <div class="content">
        <Bread :breads="breads"></Bread>
                <div class="right-desc">
                    <div class="search-bar">
                        <div class="form-group">
                            <label class="form-label">关键词</label>
                            <input type="text" class="form-control" placeholder="请输入" v-model="keyword"/>
                            <button type="button" class="btn btn-primary" @click="queryClick">查询</button>
                        </div>
                    </div>
                    <table class="res-table" v-loading="loading">
                        <thead>
                        <tr>
                            <th>题目名称</th>
                            <th>赛题来源</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item, index) in resList.list" :key="item.id">
                            <td><a href="javascript:void(0)" @click="openRes(item.id)">{{item.adTitle}}</a></td>
                            <td>{{item.source}}</td>
                        </tr>
                        </tbody>
                    </table>
                    <div class="center" v-if="resList.list.length == 0" style="margin-bottom: 24px;">暂无检索结果</div>
                    <Pager :currentPage="currentPage" @pageChange="pageChange" :totalPage="resList.totalPage"></Pager>
                    
                    
                    
                </div>
         
    </div>
</template>

<script>
import Bread from '../components/common/Bread'
import Pager from '../components/common/Pager'
import VueDatepickerUi from 'vue-datepicker-ui'
import 'vue-datepicker-ui/lib/vuedatepickerui.css'
import {ref, onMounted, getCurrentInstance} from 'vue'
import {useRoute} from 'vue-router'
export default {
    components:{Bread, Pager, Datepicker: VueDatepickerUi},
    setup(){
        const {proxy} = getCurrentInstance();
        const loading = ref(false);
        const route = useRoute();
        const date = ref();
        const id = ref('');
        const active = ref(0);
        const name = ref('');
        const keyword = ref('');
        const resList = ref({
            total: 0,
            page: 1,
            list: []
        })

        const type = ref(route.query.type)
        const breads = ref([{text:'首页', path: '/index', name: 'Index'}]);
        if(type.value =='training'){
            breads.value = [{text: '科普与培训', path: '/training', name: 'Training'}];
        }else if(type.value == 'industry'){
            breads.value = [{text: '产业专区', path: '/industry', name: 'Industry'}];
        }else if(type.value == 'contest'){
            breads.value = [{text: '竞赛专区', path: '/contest', name: 'Contest'}];
        }
  
        if(route.query.id){
            id.value = route.query.id;
            name.value = route.query.name;
        }
        breads.value.push({text: name.value});
       

        const newsItem = ref({content:{total: 1}});
        const currentPage = ref(1)



        function getResList(){
            console.log('getResList')
            loading.value = true;
            const url = '/hfapplication/gdsti/a/jinsai/list';
            var offset = (currentPage.value-1) * 10;
            console.log(offset);
            proxy.$http.post(url, {adTitle: keyword.value,offset:offset,limit:10}).then(res=>{
                loading.value = false
                if(res.data.state == 0){
                    resList.value = res.data.result;
                }
            }).catch(err=>{
                loading.value = false
            })
        }

        function queryClick(){
            currentPage.value = 1;
            getResList();
        }




        onMounted(()=>{
            getResList();
        });

        return {loading,  date, active, breads, getResList, newsItem, currentPage, type,queryClick,keyword,resList};
    },
    computed:{
        totalPage(){
            if(this.newsItem.content.length > 0){
                return this.newsItem.content.page;
            }else{
                return 1;
            }
        }
    },
    methods:{
        gotoDetail(id){
            this.$router.push({name: 'NewsDetail', path: '/newsDetail', query: {id: id, type: this.type}})
        },
        pageChange(page){
            this.currentPage = page;
            this.getResList();
        },
        openRes(id){
            this.$router.push({name: 'NewsDetail', path: '/newsDetail', query: {id: id, type: 'contest'}})
        }
    }
}
</script>

<style lang="less" scoped>
.i-pager{
    margin-top: 38px;
    margin-bottom: 27px;
}
.table td{
    font-family: SourceHanSansCN-Regular;
    font-size: 16px;
    color: rgba(0,0,0,0.85);
}
.query-box{
    margin-bottom: 16px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}
.center{
    margin-top:24px;
}
.query-btn{
    margin-left: 60px;
}
#category{
    width: 100px;
}
#key{
    width: 171px;
}
#date{
    display: inline-block;
}
.right-desc{

    display: inline-block;
    width: 100%;

    font-size: 14px;
    color: rgba(0,0,0,0.85);
    letter-spacing: 0;

    h3,h4{
        margin-top: 12px;
        margin-bottom: 4px;
        font-family: SourceHanSansCN-Bold;
        font-size: 22px;
        color: rgba(0,0,0,0.85);
        margin-bottom: 13px;
    }

    .quest-box{
        margin-bottom: 22px;
    }
    h6{
        font-family: SourceHanSansCN-Medium;
        font-size: 16px;
        color: rgba(0,0,0,0.85);
        margin-bottom: 12px;
    }
    p{
        line-height: 22px;
        margin-top: 4px;
        font-family: SourceHanSansCN-Regular;
        font-size: 14px;
        color: rgba(0,0,0,0.85);
        letter-spacing: 0;
        text-align: justify;
    }

    .contact{
        margin-top: 32px;
        h3{
            margin-bottom: 13px;
        }
        p{
            font-family: SourceHanSansCN-Regular;
            font-size: 18px;
            color: #242424;
            line-height: 25px;
        }
    }


    table{
        border-collapse: collapse;
        width: 100%;

        td,th{
            border: 1px solid #000000;
            margin: 0;
            text-align: center;
            height: 28px;
            width: 220px;
            line-height: 20px;
            font-size: 14px;
            color: rgba(0,0,0,0.85);
        }
        th{
            font-weight: bolder;
            line-height: 48px;
        }
    }

    .res-table{
        border: none;
        border-spacing: 0;
        margin-bottom: 38px;
        margin-top: 22px;
        td, th{
            border: none;
            text-align: left;
            padding-left: 17px;

        }

        thead{
            th{
                background: rgba(217,217,217,0.35);
                font-family: SourceHanSansCN-Medium;
                font-size: 16px;

            }
        }
        tbody{
            tr{
                border-bottom: 1px dashed #E7E7E7;
                td{
                    font-family: SourceHanSansCN-Regular;
                    font-size: 16px;
                    color: rgba(0,0,0,0.85);
                    line-height: 48px;
                }
            }
        }
    }
}
</style>

